<template>
  <div
    class="overlay"
    v-click-outside="closeOverlay"
  >
    <div class="close-search-overlay">
      <NavigationClose
        class="close"
        @click="closeOverlay"
      />
    </div>
    <div
      v-if="searchTermResult?.customerSuggestions?.length > 0"
      class="overlay__search-suggestions"
    >
      <div class="overlay__search-suggestions__headline">
        {{ $tv('suggestions', 'searchResults') }}
      </div>
      <div class="overlay__search-suggestions__results">
        <div
          v-for="(suggestion, index) in searchTermResult?.customerSuggestions.slice(0, 4)"
          :key="index"
        >
          <div
            class="suggestion-result"
            @click="openBuyBoxSku(suggestion.Sku)"
          >
            <CustomerItemNumberIcon /> <span>{{ suggestion.CustomerSku }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length"
      class="overlay__result"
    >
      <div class="overlay__header">
        <div>
          <span v-tv:searchResults="'AmountOfProducts'" />: <span
            v-text="searchTermResult?.count?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')"
          />
        </div>
      </div>
      <NewSearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        :search-filter="searchFilter"
        :is-inch-search="isInchSearch"
        @close-overlay="closeOverlay"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else-if="showNoProductsFound"
      class="overlay__noProducts"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
    <div
      v-else-if="searchTermResult && !searchTermResult?.customerSuggestions?.length > 0"
      class="overlay__skeleton"
    >
      <SearchSkeleton
        @close="closeOverlay"
      />
    </div>
  </div>
</template>
<script>
import NewSearchOverlayResults from '~/components/search/NewSearchOverlayResults.vue'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import CustomerItemNumberIcon from '~/assets/icons/customer_item_number.svg?component'
import SearchSkeleton from '~/components/skeletons/SearchSkeleton.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'
import {storeToRefs} from 'pinia'
import {useUserStore} from '~/store/user.js'

export default {
  components: {
    NavigationClose,
    CustomerItemNumberIcon,
    NewSearchOverlayResults,
    SearchSkeleton
  },
  async setup() {
    const {loadedCustomerUuid} = storeToRefs(useUserStore())

    return {
      currentCustomerUuid: loadedCustomerUuid
    }
  },
  props: {
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    searchFilter: {
      type: Array,
      default () {
        return []
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    },
    active: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data() {
    return {
      topResults: []
    }
  },
  methods: {
    closeOverlay(event) {
      const searchElement = document.body.getElementsByClassName('nav__main__searchbar').item(0)
      if (this.active && !searchElement.contains(event?.target)) {
        this.$emit('close-overlay')
      }
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    openBuyBoxSku(sku) {
      this.$emit('openBuyBoxSku', sku)
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
      this.$emit('close-overlay')
    }
  },
  computed: {
    mappedProducts() {
      let mappedProducts = getMappedProducts(this.searchTermResult)
      return mappedProducts?.length > 8 ? mappedProducts.slice(0, 8) : mappedProducts
    },
    showNoProductsFound() {
      return this.searchTermResult
        && this.searchTermResult.searchResults
        && this.searchTermResult.searchResults.length === 0
        && (this.currentCustomerUuid !== 'default' ? this.searchTermResult?.customerSuggestions?.length === 0 : true)
    }
  }
}
</script>

<style lang="scss">
.overlay {
  background-color: $color__content--background__secondary;
  padding: $default-margin-padding-width;
  border: 0.063rem solid $color__fst__gray__030;
  box-shadow: 0 0.1875rem 0.375rem $color__fst__gray__29;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;

  .close-search-overlay {
    display: flex;
    justify-content: flex-end;

    .close.icon--svg {
      margin-right: -.5rem;
      margin-top: -.5rem;
      position: absolute;
      font-size: 1.5rem;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
  }

  @include breakpoint-up($lg) {
    width: 64rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: $base-text-font-weight-bold;
    color: $color__fst__primary;
    margin-bottom: .5rem;
  }

  .h5 {
    margin-bottom: .5rem;
  }

  &__skeleton {
    width: 100%;
  }

  &__search-suggestions {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;

    &__headline {
      font-size: 1rem;
      font-weight: $base-text-font-weight-bold;
      color: $color__fst__primary;
      margin-bottom: .5rem;
    }

    &__results {
      display: flex;
      gap: 0.75rem;

      .suggestion-result:hover {
        border: 0.063rem solid $color__fst__primary__hover;
        box-shadow: 0 0 0 0.063rem $color__fst__primary__hover;
      }

      .suggestion-result {
        position: relative;
        border-radius: 0.2rem;
        border: 0.063rem solid $color__fst__primary;
        box-sizing: border-box;
        transition: border 0.3s;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        color: $color__fst__primary;
        background-color: $color__fst__white;

        span {
          margin-left: 1.25rem;
        }

        .icon--svg {
          position: absolute;
          top: .125rem;
          left: .375rem;
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
  }
}
</style>
